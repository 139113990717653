<template>
    <section class="component-donuts">
        <p class="text-black f-500 f-20 mb-2">
            Genero General
        </p>
        <div class="row mx-0">
            <div v-if="verGrafica" class="col-12 px-0">
                <echart :options="generoGeneral" />
            </div>
            <div v-else class="col-12 px-0 text-center">
                <img class="obj-cover" src="/img/no-imagen/no-grafica.png" />
            </div>
        </div>
    </section>
</template>
<script>
const colorsGender = [ "#B2B2FF" /* Otro */, "#FFB2C2" /* Hombre */ , "#80DCFF" /* Mujer */ ]

import Vue from 'vue'
import echarts from 'echarts'
Vue.component('echarts',echarts);

export default {
    props: {
        data: {
            type: Array,
            default: []
        },
    },
    data(){
        return {
            verGrafica: false,
            generoGeneral: {
                tooltip: {
                    show: false,
                },
                color: colorsGender,
                series: [
                    {
                        type: 'pie',
                        radius: ['40%', '70%'],
                        label: {
                            formatter: (params) => {
                                let total = this.data[params.dataIndex].total;
                                return `${params.name} \n ${total}: ${params.value}%`
                            },
                            show: true,
                            fontSize: '15',
                            edgeDistance: 10,
                            lineHeight: 15,
                        },
                        labelLine: {
                            show: true,
                            length: 15,
                            length2: 10,
                            maxSurfaceAngle: 80
                        },
                        labelLayout(params) {
                            console.log(params)
                            const isLeft = params.labelRect.x < myChart.getWidth() / 2;
                            const points = params.labelLinePoints;
                            // Update the end point.
                            points[2][0] = isLeft
                            ? params.labelRect.x
                            : params.labelRect.x + params.labelRect.width;
                            return {
                            labelLinePoints: points
                            };
                        },
                        hoverAnimation: false,
                        data: [
                            { value: 0, name: 'Otros' },
                            { value: 0, name: 'Mujeres' },
                            { value: 0, name: 'Hombres' },
                        ]
                    }
                ]
            },
        }
    },
    
    watch:{
        data(){
            this.mapearData();
        },
    },

    mounted(){
        this.mapearData();
    },

    methods: {
        mapearData(){
            if(this.data.length < 1)return this.verGrafica = false;
            let info = this.data;
        
            this.generoGeneral.series[0].data[0].value = `${info[0].porcentaje}`;
            this.generoGeneral.series[0].data[0].name = 'Otros';

            this.generoGeneral.series[0].data[1].value = `${info[1].porcentaje}`;
            this.generoGeneral.series[0].data[1].name = 'Mujeres';

            this.generoGeneral.series[0].data[2].value = `${info[2].porcentaje}`;
            this.generoGeneral.series[0].data[2].name = 'Hombres';

            this.verGrafica = true;
        },
    },
}
</script>
